import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  Routes,
  Route,
  useNavigate,
  BrowserRouter as Router
} from "react-router-dom";
import Dialer2 from './dialer2';




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <Routes>
    <Route
      path='/dialer'
      element={
        <Dialer2 />}
    />
    <Route
      path='*'
      element={
        <App />}
    />
    
   
    </Routes>
    </Router>
    
);

