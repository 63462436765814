import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Input, ButtonsContainer, CallButton } from "./atoms";
import { Web } from "sip.js";
import {
  Routes,
  Route,
  useNavigate,
  BrowserRouter as Router
} from "react-router-dom";
import { color } from "@mui/system";
import mixpanel from 'mixpanel-browser';
mixpanel.init('f4710b9de9fbf50c638ae49451c3261f', {debug: false});



const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    
    
    

    function getAudioElement(id: string): HTMLAudioElement {
        const el = document.getElementById(id);
        if (!(el instanceof HTMLAudioElement)) {
          throw new Error('Element "${id}" not found or not an audio element.');
        }
        return el;
      }
      //console.log("start sip func");
      const server = "wss://phone1.bcall.us:443/ws"; 
      const displayName = "Bcall";
    
      //console.log("run start render");
      
        const simpleUserDelegate: Web.SimpleUserDelegate = {
          onCallCreated: (): void => {
          },
          onCallAnswered: (): void => {
          },
          onCallHangup: (): void => {
            //console.log("hangup");
            window.close();
          },
          onCallReceived: (): void => {
            console.log("ring");
            
            

          }
        };
      

      var simpleUser:any;


    function LogoSvg({
        size = 2, // or any default size of your choice
        color = "black" // or any color of your choice
      }) {
        return (
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.62 195.41">
            width={size} // added size here
          height={size}
      <path  fill="none"
            stroke="#ffffff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="16.52px" d="M148.29,106.01c0-20.03-17.39-36.52-38.51-36.52-18.49,0-34.11,12.62-37.73,29.2-.95,4.34-4.98,7.32-9.42,7.32h-11.68c-5.82,0-10.4-5.21-9.39-10.95,5.58-31.8,35.02-56.36,70.03-56.36s66.23,26.08,70.47,59.27c.59,4.64,4.69,8.03,9.37,8.03h13.19c5.69,0,10.1-4.98,9.44-10.63-5.66-48.7-49.7-87.12-102.47-87.12C55.04,8.26,8.5,52.38,8.5,106.01"/>
      <path  fill="#ffffff" d="M120.76,165.99c-6.28,.74-12.62,.74-18.91,0-14.49-1.71-40.64-6.59-41.71-19.67-1.49-18.13-16.34-17.05-16.34-17.05,0,0-25.96,.03-34.27,0-5.67-.02-8.47,4.97-8.47,4.97-9.8,15.82,49.93,61.15,110.25,61.15s120.05-45.34,110.25-61.15c0,0-2.81-4.99-8.47-4.97-8.31,.03-34.27,0-34.27,0,0,0-14.85-1.08-16.34,17.05-1.08,13.08-27.22,17.96-41.71,19.67Z"/>
    </svg>
        )
      }
    
export default function Dialer2({  }) {
  const [text, setText] = useState('Connecting');
  const [count, setCount] = useState("Connecting");
const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
const [digit, setDigit] = useState("");

  
  

  function LogoSvggreen({
    size = 2, // or any default size of your choice
    color = "black" // or any color of your choice
  }) {
    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.62 195.41">
        width={size} // added size here
      height={size}
  <path  fill="none"
        stroke="#8bc34a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="16.52px" d="M148.29,106.01c0-20.03-17.39-36.52-38.51-36.52-18.49,0-34.11,12.62-37.73,29.2-.95,4.34-4.98,7.32-9.42,7.32h-11.68c-5.82,0-10.4-5.21-9.39-10.95,5.58-31.8,35.02-56.36,70.03-56.36s66.23,26.08,70.47,59.27c.59,4.64,4.69,8.03,9.37,8.03h13.19c5.69,0,10.1-4.98,9.44-10.63-5.66-48.7-49.7-87.12-102.47-87.12C55.04,8.26,8.5,52.38,8.5,106.01"/>
  <path  fill="#8bc34a" d="M120.76,165.99c-6.28,.74-12.62,.74-18.91,0-14.49-1.71-40.64-6.59-41.71-19.67-1.49-18.13-16.34-17.05-16.34-17.05,0,0-25.96,.03-34.27,0-5.67-.02-8.47,4.97-8.47,4.97-9.8,15.82,49.93,61.15,110.25,61.15s120.05-45.34,110.25-61.15c0,0-2.81-4.99-8.47-4.97-8.31,.03-34.27,0-34.27,0,0,0-14.85-1.08-16.34,17.05-1.08,13.08-27.22,17.96-41.71,19.67Z"/>
  </svg>
  )
  }
  
  function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Call to  '}
        <Link color="inherit" href="https://bcall.us/">
          Bcall.us
        </Link>{' '}
      </Typography>
    );
  }

  



  

  

    return (
        <><><audio autoPlay>
          <source src="ring.mp3"></source>
        </audio></>
        <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box 
          sx={{
            marginTop: 8,
            background: '#232323',
            boxshadow: '0 5px 10px -5px rgba(0, 0, 0, 0.2)',
            borderRadius: '22.5px',
            padding: '15px',
            maxWidth: '444px',
            minWidth: '115px'
            
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center" >
          <Avatar sx={{  bgcolor: '#263238'  }}>
            <LogoSvggreen />
          </Avatar>
          </Stack>
          
          <Sip simpleUser={simpleUser}/>
          <Copyright color="common.white"  marginTop='20px'/> 
        </Box>
        </Container>
        </>
  );


}



function Sip({simpleUsera}:any)
{
  //console.log("enter sip");
  var userpass = "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  userpass=sessionStorage.getItem("userpass");
    //console.log("userpass-----"+userpass);
  let navigate = useNavigate();
    const [buttonpad, setbutton] = useState("none");
    const [activcall, setActivecall] = useState("none");
    const [digit, setDigit] = useState("Connect");
    const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
    
    console.log(sessionStorage.getItem("turnpassword"));

    const options: Web.SimpleUserOptions = {
      delegate: simpleUserDelegate,
      media: {
        remote: {
          audio: getAudioElement("remoteAudio")
        }
      },
      aor: "sip:"+userpass+"@bcall.us",
      userAgentOptions: {
        sessionDescriptionHandlerFactoryOptions: {
          peerConnectionConfiguration: {
            iceServers: [{
              urls: [ "stun:turn.bcall.us" ]
           }, {
              username: sessionStorage.getItem("turnuser"),
              credential: sessionStorage.getItem("turnpassword"),
              
              urls: [
                  "turn:turn.bcall.us:3478"
              ]
           }]
          },
        },
        // logLevel: "error",
        displayName,
        authorizationPassword: userpass,
        authorizationUsername: userpass
      }
    };
    
      function answer( )
     {
      //event.preventDefault();
      //console.log(`call received`);
      setbutton("none");
      setActivecall("flex");
      simpleUser.answer().catch((error: Error) => {
        console.error(` failed to answer call`);
        console.error(error);
        alert(`[ Failed to answer call.\n` + error);
      });
    }; 

    function hangup( )
    {
     
     simpleUser.hangup();
     simpleUser.disconnect();
     simpleUser.unregister();
     //window.close();
   };  

   function senddigit(digita: string)
   {
    setDigit(digita);
    simpleUser.sendDTMF(digita);
   }

      

      useEffect(() => {
        mixpanel.track('Click call dialer');
        mixpanel.people.increment("Show_click_call_count", 1);
        //console.log("render sip");
        simpleUser=new Web.SimpleUser(server, options);
        const simpleUserDelegate: Web.SimpleUserDelegate = {
          onCallCreated: (): void => {
          },
          onCallAnswered: (): void => {
            setDigit("Talk");
            //console.log(sessionStorage.getItem("ext"));
            var str = "aa";
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
            str = sessionStorage.getItem("ext")+ "#";
            //console.log(str);
            for (var i = 0; i < str.length; i++) {
              //console.log(str[i]);
              simpleUser.sendDTMF(str[i]);
            }
            
          },
          onCallHangup: (): void => {
            //console.log("hangup");
            navigate(-1);
            //window.close();
          },
          onCallReceived: (): void => {
            //console.log("ring1234");
            setbutton("flex");
            
            
  
          }
        };
  
        simpleUser.delegate = simpleUserDelegate;

        //console.log("run useEffect");
        simpleUser.connect()
        .then(() => {
            //console.log("connect sip");
            simpleUser.register().then(() => {
               //console.log("register");
               setActivecall("flex");
               setDigit("Dialing");
               simpleUser.call("sip:011"+ sessionStorage.getItem("numberadv") +"@bcall.us" )
            }).catch((error: Error) => {
                console.log("error register");
              });
            //return simpleUser
          })
          .catch((error: Error) => {
            console.log("error");
          });
      }, []);
      
      
      return (
        <>
        <Container  component="main" maxWidth="xs">
        <Typography sx={{ marginTop: 2}} component="h1" variant="h5" color="common.white" align="center" bgcolor="#2D2D27">
          {digit}
          </Typography>
          
        
          <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '10px', // מרווח בין הלחצנים
        justifyContent: 'center',
      }}
    >
      {buttons.map((char, index) => (
        <Button
          key={char}
          onClick={() => senddigit(char)}
          style={{
            minWidth: '90px',
            color: '#cfd8dc',
            fontSize: '1.5rem',
          }}
        >
          {char}
        </Button>
      ))}
    </div>
          
              
          <Box component="form"    display={activcall} alignItems="center" justifyContent="center" noValidate sx={{ mt: 1 }}>
          <Button color="error" onClick={() => {hangup();}}
                  variant="contained"
                  sx={{ 
                    borderRadius: '20px',
                  }}
                ><LogoSvg /></Button>
          
          
    
          
          </Box>
          </Container>
         </>
      );
}