import React ,{ useEffect, useState } from 'react';
import logo from './logo.svg';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Input, ButtonsContainer, CallButton } from "./atoms";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ReactCountryFlag from "react-country-flag"
import Dialer2 from './dialer2';
import mixpanel from 'mixpanel-browser'
import {
  Routes,
  Route,
  useNavigate,
  BrowserRouter as Router
} from "react-router-dom";
import { Web } from "sip.js";
mixpanel.init('f4710b9de9fbf50c638ae49451c3261f', {debug: false});
/* eslint-disable import/first */
import ReactGA from "react-ga4";
ReactGA.initialize("G-M6184ES3SR");

function LogoSvg({
  size = 2, // or any default size of your choice
  color = "black" // or any color of your choice
}) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.62 195.41">
      width={size} // added size here
    height={size}
<path  fill="none"
      stroke="#8bc34a"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="16.52px" d="M148.29,106.01c0-20.03-17.39-36.52-38.51-36.52-18.49,0-34.11,12.62-37.73,29.2-.95,4.34-4.98,7.32-9.42,7.32h-11.68c-5.82,0-10.4-5.21-9.39-10.95,5.58-31.8,35.02-56.36,70.03-56.36s66.23,26.08,70.47,59.27c.59,4.64,4.69,8.03,9.37,8.03h13.19c5.69,0,10.1-4.98,9.44-10.63-5.66-48.7-49.7-87.12-102.47-87.12C55.04,8.26,8.5,52.38,8.5,106.01"/>
<path  fill="#8bc34a" d="M120.76,165.99c-6.28,.74-12.62,.74-18.91,0-14.49-1.71-40.64-6.59-41.71-19.67-1.49-18.13-16.34-17.05-16.34-17.05,0,0-25.96,.03-34.27,0-5.67-.02-8.47,4.97-8.47,4.97-9.8,15.82,49.93,61.15,110.25,61.15s120.05-45.34,110.25-61.15c0,0-2.81-4.99-8.47-4.97-8.31,.03-34.27,0-34.27,0,0,0-14.85-1.08-16.34,17.05-1.08,13.08-27.22,17.96-41.71,19.67Z"/>
</svg>
)
}

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Call to  '}
      <Link color="inherit" href="https://bcall.us/">
        Bcall.us
      </Link>{' '}
    </Typography>
  );
}

function getAudioElement(id: string): HTMLAudioElement {
  const el = document.getElementById(id);
  if (!(el instanceof HTMLAudioElement)) {
    throw new Error(`Element "${id}" not found or not an audio element.`);
  }
  return el;
}



function App() {
  let navigate = useNavigate();
  const queryString = window.location.pathname;

  
  const [number, setNumber] = useState("");
  const [numberadv, setNumberadv] = useState("");
  const [ext, setExt] = useState("");
  const [country, setCountry] = useState("");
  const [desktop, setDesktop] = useState(true);
  const [phone, setPhone] = useState(false);
  const [status, setStatus] = useState(false);
  const [name, setName] = useState(queryString.slice(1));
  const [realnumber, setRealumber] = useState("");
  const [display, setDisplay] = useState("none");
  const [displayrealnumber, setDisplayRealnumber] = useState("none");


  
  //console.log(queryString);
  

  useEffect(() => {
    //console.log("render");
    fetch("https://dialer1.bcall.us/dialer?name="+queryString.slice(1))
      .then(res => res.json())
      .then(
        (result) => {
          
          if(result.status=="404")
          {
            setStatus(true);
          }else{
            setStatus(false);
            mixpanel.identify(queryString.slice(1));
            mixpanel.track('Show dialer', {
              'Country': result.country,
              'Device':result.device
            })
            mixpanel.people.increment("Show_dialer_count", 1);
          //console.log(result);
          setNumber(result.number);
          setNumberadv(result.numberadv);
          setExt(result.ext);
          setCountry(result.country);
          setRealumber(result.realnumber);
          if(result.realnumber !=""){
            setDisplayRealnumber("flex");
          }else{
            setDesktop(true);
          }
          
          sessionStorage.setItem("ext", result.ext);
          sessionStorage.setItem("number", result.number);
          sessionStorage.setItem("numberadv", result.numberadv);
          sessionStorage.setItem("realnumber", result.realnumber);
          sessionStorage.setItem("userpass", result.userpass);
          sessionStorage.setItem("turnuser", result.turn.username);
          sessionStorage.setItem("turnpassword", result.turn.password);
          if(result.device!="mobile")
          {
            setDesktop(true);
          }
          if(result.action=="Phone")
          {
            //setPhone(false);
            setDesktop(true);
          }
        }

        setDisplay("flex");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      )
  }, [])

  if(status)
  {
  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
      <Box display={display}
        sx={{
          marginTop: 8,
          flexDirection: 'column',
          alignItems: 'center',
          background: '#263238',
          borderRadius: '22.5px',
          padding: '15px'
        }}
      >
 <Avatar sx={{ m: 1, bgcolor: '#263238' }}>
          <LogoSvg />
        </Avatar>
        <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#DCDCDC',
          borderRadius: '22.5px',
          padding: '15px',
          width:'80%',
          height: '80%'
        }}
      >
  <Typography sx={{
          padding: '15px' }}
          component="h1" variant="h5"  align="center">
       Customer not found
  </Typography>  
  </Box>   
    <Copyright color="common.white"  />  
    </Box>
    
    </Container>
  );
  }


  if(phone)
  {
  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
      <Box display={display}
        sx={{
          marginTop: 8,
          flexDirection: 'column',
          alignItems: 'center',
          background: '#263238',
          borderRadius: '22.5px',
          padding: '15px'
        }}
      >
 <Avatar sx={{ m: 1, bgcolor: '#263238' }}>
          <LogoSvg />
        </Avatar>
        <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#DCDCDC',
          borderRadius: '22.5px',
          padding: '15px',
          width:'80%',
          height: '80%'
        }}
      >
  <Box display={displayrealnumber}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            background: '#DCDCDC',
            borderRadius: '22.5px',
            padding: '15px',
            width:'80%',
            height: '80%'
          }}
        >
    <ReactCountryFlag
                countryCode={country}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
                style={{
                  width: '2em',
                  height: '2em',
              }}
                title={country}
            />
    <Typography sx={{padding: '15px' }}component="h1" variant="h5"  align="center">
    {number}
    </Typography>   
 
    </Box>   
  </Box>   
    <Copyright color="common.white"  />  
    </Box>
    
    </Container>
  );
  }


  if(desktop)
  {
    return (
      <>
      
    
      <Container  component="main" maxWidth="xs">
      <CssBaseline />
        <Box display={display}
          sx={{
            marginTop: 8,
            flexDirection: 'column',
            alignItems: 'center',
            background: '#263238',
            borderRadius: '22.5px',
            padding: '15px'
          }}
        >
   <Avatar sx={{ m: 1, bgcolor: '#263238' }}>
            <LogoSvg />
          </Avatar>
          <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#DCDCDC',
            borderRadius: '22.5px',
            padding: '15px',
            width:'80%',
            height: '80%'
          }}
        >
    <Typography sx={{
            padding: '15px' }}
            component="h1" variant="h5"  align="center">
         {name}
    </Typography>  
    <Typography component="h1" variant="h5" color="common.white" align="center"> </Typography>     
      
     
      <Button onClick={() => {
    navigate('/dialer');
  }} sx={{padding: '15px',borderRadius: '22.5px' }} color="success" variant="contained"><HeadsetMicIcon />  Call Now</Button>
      </Box>
      
    <Copyright color="common.white"  />  
    </Box>
    
    </Container>
    </>
  );
            }
            
            else{
              return (
                <Container component="main" maxWidth="xs">
                <CssBaseline />
                  <Box display={display}
                    sx={{
                      marginTop: 8,
                      flexDirection: 'column',
                      alignItems: 'center',
                      background: '#263238',
                      borderRadius: '22.5px',
                      padding: '15px'
                    }}
                  >
             <Avatar sx={{ m: 1, bgcolor: '#263238' }}>
                      <LogoSvg />
                    </Avatar>
                    <Box 
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      background: '#DCDCDC',
                      borderRadius: '22.5px',
                      padding: '15px',
                      width:'80%',
                      height: '80%'
                    }}
                  >
              <Typography sx={{
                      padding: '15px' }}
                      component="h1" variant="h5"  align="center">
                   {name}
              </Typography>  
              <a style={{textDecoration: 'none'}} href={"tel:" +realnumber + "," +ext +"#"}><Button  sx={{padding: '15px',borderRadius: '22.5px' }} color="success" variant="contained">Click to Call</Button></a>
              </Box>   
                <Copyright color="common.white"  />  
                </Box>
                
                </Container>
              );
            }
}

export default App;
